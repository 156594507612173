.root {
    @apply font-bold px-4 py-2 text-xl text-center bg-black dark:bg-white text-pureWhite dark:text-black w-auto rounded-none border-2 border-transparent;
    /*border-color: #000000;*/
    /*min-width: 120px;*/
    cursor: pointer;
    line-height: 28px;
    white-space: nowrap;

    /*
    @apply bg-accent-9 text-accent-0 cursor-pointer inline-flex
    px-10 py-5 leading-6 transition ease-in-out duration-150
    shadow-sm text-center justify-center uppercase
    border border-transparent items-center text-sm font-semibold
    tracking-wide;
    max-height: 64px;
    */
  }

  .root:hover {
    @apply bg-primary text-pureWhite;
    border-color: var(--clrPrimary);

    /*@apply border-accent-9 bg-accent-6;*/
  }


/*  
  .root:focus {
    @apply shadow-outline-normal outline-none;
  }
  
  .root[data-active] {
    @apply bg-accent-6;
  }
  
  .loading {
    @apply bg-accent-1 text-accent-3 border-accent-2 cursor-not-allowed;
  }
  */

  .outline {
    @apply border-pureWhite bg-primary text-pureWhite hover:bg-black hover:border-black;
  }
  
  /*
  .ghost {
    @apply border border-accent-2 bg-accent-0 text-accent-9 text-sm;
  }
  
  .ghost:hover {
    @apply border-accent-9 bg-accent-9 text-accent-0;
  }
  
  .disabled,
  .disabled:hover {
    @apply text-accent-4 border-accent-2 bg-accent-1 cursor-not-allowed;
    filter: grayscale(1);
    -webkit-transform: translateZ(0);
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
  }
  
  .progress {
  }
  */