#main-menu {
    @apply flex items-center justify-between text-xl font-bold;
}
#main-menu .menu {
    display: none;
}
#main-menu .logo {
    @apply mx-auto z-10;
    /*padding-left: 20px;*/
}
#main-menu .logo>div {
    @apply block !important;
}
#main-menu .menu.mobile {
    @apply absolute top-0 left-0 flex flex-col flex-1 bg-white dark:bg-black w-screen h-screen justify-center items-center leading-loose;
}
#main-menu .menu.mobile div {
    @apply  flex flex-col items-center;
}
#main-menu .menu a {
    @apply px-4 py-3;
    line-height: 28px;
}
#main-menu .menu button,
#main-menu .menu a.button {
    @apply mt-10 lg:mt-0;
    @apply bg-transparent border-2 border-dotted border-black dark:border-white hover:border-primary text-black dark:text-white;
}
#main-menu .menu button:hover,
#main-menu .menu a.button:hover,
#main-menu .menu .auth:hover {
    @apply bg-primary text-pureWhite border-primary;
}
#main-menu .menu .auth {
    @apply mt-4 text-center;
    border-radius: 50%;
    /*min-width: 54px;*/
    /*min-height: 54px;*/
    cursor: pointer;
}
#main-menu #mobile-icon {
    @apply block /*lg:hidden*/ z-10;
    cursor: pointer;
}
.searchDesktop {
    @apply ml-auto hidden;
}
.searchMobile {
    @apply z-10;
}

@screen xl {
    #main-menu .logo {
        @apply mx-0 pl-0 pr-4 z-10 flex;
        /*min-width: 280px;*/
    }
    .searchMobile {
        display: none;
    }
    .searchDesktop {
        display: inline;
    }
    #main-menu #mobile-icon {
        display: none;
    }
    #main-menu .menu {
        @apply flex flex-row items-center justify-start flex-1;
    }
    #main-menu .menu .alignRight {
        @apply ml-auto;
    }
    #main-menu .menu .auth {
        @apply ml-4 mt-0;
    }
}
@media screen and (max-height: 400px) {
    #main-menu .menu.mobile {
        @apply flex-row;
    }
    #main-menu .menu.mobile div {
        @apply flex flex-col items-start;
    }
    #main-menu .menu.mobile>div,
    #main-menu .menu.mobile>div+div{
        flex: 0 0 200px;
    }
    #main-menu .menu .auth {
        @apply mt-0;
    }
}