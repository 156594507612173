.footer {
    @apply bg-accent-1 dark:bg-accent-4 pt-16 pb-8;
}
.footer #content {
    @apply grid grid-cols-1 md:grid-cols-2 md:gap-x-16 lg:gap-x-32 gap-y-20 md:gap-y-32 mb-16;
}
.footer #content #brand {
    @apply flex flex-col justify-center md:justify-start text-sm;
}
.footer #content #brand #bio {
    @apply md:max-w-sm mt-10 mb-5 mx-auto md:mx-0 text-center md:text-left;
}
.footer #content #brand #certification-badges {
    @apply flex flex-col md:flex-row;
    gap: 20px;
}
.footer #content #brand #certification-badges img,
#testdome-badge {
    max-height: 120px;
}
.footer #content #links {
    @apply flex flex-col;
}
.footer #content #links h3 {
    @apply text-4xl lg:text-5xl font-bold tracking-tighter leading-tight lg:pr-4 mb-10 text-center md:text-left;
}
.footer #content #links .subscribe {
    @apply flex text-4xl space-x-8 items-center justify-center md:justify-start;
}
.footer #content #links .socials {
    @apply prose prose-xl flex space-x-8 items-center justify-center md:justify-start mt-10 dark:text-white;
}
.footer #content #links .socials a {
    @apply hover:text-primary;
}
.footer #legal {
    @apply mt-8 flex flex-col md:flex-row items-center md:items-start justify-center md:justify-start leading-10 md:leading-none;
    white-space: break-spaces;
}
.footer #legal a:before {
    content: ' / ';
}
.footer #badges {
    @apply mt-4 text-center md:text-left;
}
.footer #badges a {
    @apply inline-block mr-2;
}
@media only screen and (max-width: 768px) {
    .footer #content #brand {
        align-items: center;
    }
    .footer #legal a:before {
        content: unset;
    }
}