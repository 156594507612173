#site-header {
    @apply py-4 z-50;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transform: translateY(0);
    transition: transform var(--tx-speed) ease;
}
#site-header.shadow {
    @apply bg-accent-1 dark:bg-accent-6;
}
#site-header.out {
    transform: translateY(-100%);
}