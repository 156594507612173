/**
 * TAILWIND
 */
@tailwind base;
@layer base {
    b, strong {
        font-weight: 900;
    }
    [type='text'],
    [type='email'],
    [type='url'], 
    [type='password'], 
    [type='number'], 
    [type='date'], 
    [type='datetime-local'], 
    [type='month'], 
    [type='search'], 
    [type='tel'], 
    [type='time'], 
    [type='week'], 
    [multiple], 
    textarea, 
    select {
        @apply px-0 py-2 block w-full rounded-none appearance-none bg-transparent border-t-0 border-l-0 border-r-0 border-b m-0;
        font-size: inherit;
        font-weight: inherit;
        background-color: transparent!important;
        -webkit-autofill: unset;
        /*border-color: var(--clrLightShades)!important;*/
    }
    *:focus {
        --tw-ring-color: unset!important;
        border-color: var(--clrPrimary)!important;
    }
    hr {
        /*border-style: unset;*/
        border-color: var(--clrLightShades);
    }
    /*
    *, *::before, *::after {
        border-style: unset;
        border-color: unset;
    }
    */
    /*
    h2, h3, h4, h5 {
        font-weight: 300!important;
    }
    */
    /*
    h1 {
      @apply text-5xl;
    }
    h2 {
      @apply text-xl;
    }
    h3 {
      @apply text-lg;
    }
    a {
      @apply text-blue-600 underline;
    }
    */
}
@tailwind components;
@tailwind utilities;
@tailwind forms;

/**
 * CORE
 */
:root {
    --clrPrimary: #DD0000;
    --clrLightShades: #F0EFF4;
    --clrLightShadesDim: #2B282D33;
    --clrWhiteDim: #FFFFFF77;
    --clrBlackDim: #00000077;
    --clrLightAccent: #6B818C;
    --clrDarkShades: #1D1A1F;
    --clrDarkShades2: #201d22;
    --clrDarkAccent: #2B2D42;
    --clrPureWhite: #FFFFFF;
    --clrPureBlack: #000000;
    --tx-speed: 250ms; 
}

body {
    font-family: 'Product Sans';
    font-weight: 300;
    /*letter-spacing: 0.25px;*/
    @apply text-black dark:bg-black dark:text-white;
}

/* UI BUG: @640px hscroll bar */
main {
    /*margin-top: 100px;*/
    /*padding-top: 100px;*/
    min-height: 100vh;
    /*min-height: calc( 100vh - 94px );*/
    /*overflow-x: hidden;*/
}

a:hover,
a:hover strong {
    color: var(--clrPrimary);
}
h1 {
    @apply text-6xl md:text-7xl lg:text-8xl font-bold tracking-tighter leading-none /*text-center md:text-left*/ mb-12 max-w-6xl;
}
h1 strong {
    @apply text-primary;
    /*
    background: var(--clrPrimary);
    color: #ffffff;
    */
    /*
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-size: 3px;
    text-decoration-color: var(--clrPrimary);
    */
}
h2 {
    font-size: 2.5em;
}

/* CHROME (AUTOFILL) */
@media (prefers-color-scheme: light) {
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--clrDarkShades);
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}
@media (prefers-color-scheme: dark) {
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--clrLightShades);
        -webkit-box-shadow: 0 0 0px 1000px var(--clrDarkShades) inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}

/* BUTTON */
/*
button,
a.button,
input[type="submit"] {
    @apply px-4 py-3 text-xl text-center font-bold bg-black dark:bg-white text-pureWhite dark:text-black w-auto rounded-none;
    border-color: #000000;
    cursor: pointer;
    line-height: 28px;
    white-space: nowrap;
}

button:hover:enabled,
button[type="submit"]:hover:enabled,
a.button:hover:enabled,
input[type="submit"]:hover:enabled {
    @apply bg-primary text-pureWhite;
    border-color: var(--clrPrimary);
}
*/

/*
button:hover:enabled svg path,
button:hover:enabled .svg-inline--fa path,
a:hover:enabled .svg-inline--fa path,
a.button:hover:enabled svg path, {
    fill: var(--clrPureWhite);
}
*/

/**
 * Tailwind
 */
.container {
    @apply mx-auto px-5;
}
main>.container {
    /*justify-content: center;*/
    flex-flow: column;
    min-height: 100vh;
    /*min-height: calc( 100vh - 100px);*/
    display: flex;
}
main>.container>article {
    padding: 100px 0;
}

@media screen and (min-width: 768px) {
    main>.container>article {
        padding: 150px 0;
    }
}

/* Tailwind Dark Mode Fix */
@media (prefers-color-scheme: dark) {
    .dark\:block {
        display: block!important;
    }
    hr {
        border-color: var(--clrLightShades)!important;
    }
}

.error {
    color: var(--clrPrimary);
    font-weight: 900;
}

.bgImage {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-image: url(https://source.unsplash.com/daily?white);
    /*url(https://source.unsplash.com/daily?water&lossless=true);*/
    background-size: cover;
    background-position: center center;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    animation-fill-mode: forwards;
}
@-webkit-keyframes fadeIn {
    0% { opacity: 0;}
    /*99% { opacity: 1;width: 100%; height: 100%;}*/
    100% { opacity: 1;}
}  
@keyframes fadeIn {
    0% { opacity: 0;}
    100% { opacity: 1;}
}
@media (prefers-color-scheme: dark) {
    .bgImage {
        background-image: url(https://source.unsplash.com/daily?dark);
    }
}

/**
 * CHATBOT
 */
.btnChatbot {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: var(--clrDarkShades);
    color: #ffffff;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    outline: none;
    padding-top: 8px;
    min-width: unset;
}
.btnChatbot:focus { 
    outline: none; 
}
.btnChatbot .svg-inline--fa {
    width: 28px;
    height: 28px;
    vertical-align: middle;
    margin-top: 5px;
}
.btnChatbot:hover,
.btnChatbot.show {
    background-color: var(--clrPrimary);
}
.btnChatbot:hover .svg-inline--fa path {
    fill: #ffffff;
}
.react-chatbot-kit-chat-container *:focus {
    outline: none; 
}
.react-chatbot-kit-chat-container {
    position: fixed!important;
    bottom: 100px;
    width: 300px!important;
    right: 30px;
    border: solid 5px var(--clrLightShades);
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px rgba(29,26,31,0.5);
}
.react-chatbot-kit-chat-container .headerTitle {
    background: var(--clrLightShades);
    color: var(--clrDarkShades);
    padding: 10px 15px;
}
.react-chatbot-kit-chat-message-container {
    overflow-x: unset!important;
    overflow-y: scroll;
}
.react-chatbot-kit-chat-btn-send svg {
    margin: auto;
}
.react-chatbot-kit-chat-inner-container {
    background-color: var(--clrLightShades);
    color: #ffffff;
}
.react-chatbot-kit-chat-bot-message-container svg {
    color: var(--clrLightAccent);
    margin-top: 10px;;
    margin-right: 12.5px;
}
.react-chatbot-kit-chat-btn-send-icon path,
.react-chatbot-kit-chat-btn-send-icon:hover path {
    fill: #ffffff!important;
}
.react-chatbot-kit-chat-input {
    color: var(--clrDarkShades);
}
.react-chatbot-kit-user-avatar-container {
    background-color: var(--clrDarkAccent)!important;
}
.react-chatbot-kit-chat-bot-message,
.react-chatbot-kit-user-chat-message {
    font-size: unset!important;
    width: 200px!important;
}
.react-chatbot-kit-chat-bot-message a,
.react-chatbot-kit-chat-bot-message a:hover {
    color: #ffffff;
    font-weight: 900;
    text-decoration: underline;
}

/**
 * GIST
 */
.gist .gist-data,
.gist .gist-file,
.gist .gist-meta,
.gist .gist-data td {
    border-radius: 0!important;
    @apply bg-pureWhite dark:bg-black !important;
}
.gist .gist-file {
    @apply border-white dark:border-accent-4 !important;
}
.gist .gist-data {
    @apply border-b border-white dark:border-accent-4 !important;
}
.gist .blob-code-inner *,
.gist .highlight * {
    @apply text-black dark:text-white !important;
}
.gist tbody tr {
    border-bottom: unset!important;
}

/**
 * FORMS
 */
.submitting,
.submitted,
.frm {
     @apply prose prose-xl dark:prose-dark leading-tight;
}
.frm .row {
    @apply block sm:flex;
}
.frm .row .field {
    @apply sm:w-1/2 sm:mr-4;
}
.frm .row .field:last-child {
     @apply sm:mr-0;
}
.frm .field {
     @apply block mb-5 bg-transparent;
}
.frm input[type="submit"] {
     @apply my-10;
}

@media (prefers-color-scheme: dark) {
    .lightShadow {
        /*@apply bg-accent-1 dark:bg-accent-4;*/
        /*@apply bg-gradient-to-b from-bg-pureBlack;*/
        /*background: linear-gradient(180deg, rgba(0,0,0,0.9) 20%, rgba(0,0,0,0) 100%);*/
    }
}

@media (prefers-color-scheme: light) {
    .lightShadow {
        /*@apply bg-accent-1 dark:bg-accent-4;*/
        /*@apply bg-gradient-to-b from-bg-pureBlack;*/
        /*background: linear-gradient(180deg, rgba(255,255,255,0.9) 20%, rgba(255,255,255,0) 100%);*/
    }
}