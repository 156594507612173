.root {
  @apply bg-primary fixed bottom-0 w-full z-30 transition-all duration-300 ease-out;
}

.root a {
  @apply text-pureWhite underline;
}

/*
.root button {
  @apply border-pureWhite bg-primary text-pureWhite hover:bg-black hover:border-black;
}

.root [data-theme='dark'] {
  @apply hover:bg-white;
}
*/

.root div[class="container"] {
  @apply text-center p-5 text-white flex flex-col justify-center items-center;
  @apply lg:flex lg:flex-row lg:text-left;
}
.root div[class="container"] button {
  @apply mt-5;
  @apply lg:mt-0 lg:mr-0;
}