.root {
}
.button {
    @apply font-bold px-4 py-3 text-xl text-center bg-black dark:bg-white text-pureWhite w-auto rounded-none;
    border-color: #000000;
    /*min-width: 120px;*/
    cursor: pointer;
    line-height: 28px;
    white-space: nowrap;
}
.button:hover {
    @apply bg-primary text-pureWhite;
    border-color: var(--clrPrimary);
}
