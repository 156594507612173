.submitting,
.submitted,
.frm {
    @apply prose prose-xl dark:prose-dark;
}
.frm .row {
    @apply block sm:flex;
}
.frm .row .field {
    @apply sm:w-1/2 sm:mr-4;
}
.frm .row .field:last-child {
    @apply sm:mr-0;
}
.frm .field {
    @apply block mb-5 bg-transparent;
}
.frm input[type="submit"],
.frm button[type="submit"] {
    @apply my-10 flex items-center;
}
.frm button[type="submit"]:hover:enabled,
.frm input[type="submit"]:hover:enabled {
    @apply bg-primary text-pureWhite;
    border-color: var(--clrPrimary);
}
.frm button[type="submit"]:disabled,
.frm input[type="submit"]:disabled {
    @apply bg-lightAccent;
}
.error {
    color: var(--clrPrimary);
    font-weight: 900;
}