@font-face {
  font-family: 'Product Sans';
  font-style: thin;
  font-weight: 100;
  font-display: swap;
  src: local("Product Sans Thin"),  local("Product-Sans-Thin"), url(/fonts/google-product-sans-normal-100.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Product Sans"),  local("Product-Sans"), url(/fonts/google-product-sans-normal-300.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Product Sans Regular"),  local("Product-Sans-Regular"), url(/fonts/google-product-sans-normal-400.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Product Sans Regular 2"),  local("Product-Sans-Regular-2"), url(/fonts/google-product-sans-normal-500.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Product Sans Bold"),  local("Product-Sans-Bold"), url(/fonts/google-product-sans-normal-700.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Product Sans Black"),  local("Product-Sans-Black"), url(/fonts/google-product-sans-normal-900.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}